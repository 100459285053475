import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';
import {
  OnboardingContainer,
  SignOutContainer,
  AppContainer,
  SmartMeterContainer,
  EditContainer,
} from '../containers';
import ContextProvider from '../context/ContextProvider';

const AppRouter = () => {
  const [isOnboarded, setIsOnboarded] = useState(false);
  const isAuthenticated = true;

  return (
    <ContextProvider>
      <Router>
        <Switch>
          <Route path="/sign-out" component={SignOutContainer} />
          {!isOnboarded && (
            <Route path="/onboarding">
              <OnboardingContainer />
            </Route>
          )}
          {isAuthenticated && <Route path="/app" component={AppContainer} />}
          {isAuthenticated && (
            <Route path="/smart-meter">
              <SmartMeterContainer />
            </Route>
          )}
          {isAuthenticated && (
            <Route path="/edit">
              <EditContainer />
            </Route>
          )}
          {/* Router Switch does not support fragments so we have to use two conditionals here */}
          {!isOnboarded ? (
            <Route path="/" exact>
              <Redirect to="/onboarding" />
            </Route>
          ) : (
            <Route path="/" exact>
              <Redirect to="/app" />
            </Route>
          )}
        </Switch>
      </Router>
    </ContextProvider>
  );
};

export default AppRouter;
