import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { getUserDetails } from '../../api/Optimise';
import { AppContext } from '../../context/AppContext';

const Container = styled.div`
  margin-top: 24px;
`;

const Title = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #212529;
  margin-bottom: 5px;
  display: flex;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const DateSelector = styled.div`
  background-color: #eff2f6;
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
  }

  input {
    background: transparent;
    border: none;
    font-size: 14px;
    width: 100%;
    cursor: pointer;

    @media (min-width: 576px) {
      font-size: 16px;
    }
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 15px;
  pointer-events: none;
  z-index: 1;
`;

const DatePickerLayout = ({ children }) => {
  const { timestamp } = useParams();
  const {
    selectedDate,
    setSelectedDate,
    userDetails,
    setUserDetails,
    userSub,
    datePickerWarning,
  } = useContext(AppContext);

  useEffect(() => {
    if (timestamp) {
      const date = moment.unix(timestamp).toDate();
      setSelectedDate(date);
    }

    getUserDetails(userSub).then(user => {
      setUserDetails(user);
      if (!timestamp) {
        const date = user.analytics_data_range.end_date
          ? moment(user.analytics_data_range.end_date).toDate()
          : moment().subtract(1, 'days').toDate();
        setSelectedDate(date);
      }
    });
  }, []);

  return (
    <div>
      {
        <Container>
          {!!datePickerWarning && datePickerWarning}
          <Title>Date</Title>
          <DateSelector>
            <DatePicker
              selected={selectedDate}
              onChange={setSelectedDate}
              dateFormat="dd/MM/yy"
              disabled={!userDetails?.analytics_data_range}
              maxDate={new Date(userDetails?.analytics_data_range.end_date)}
              minDate={new Date(userDetails?.analytics_data_range.start_date)}
            />
            <StyledFontAwesomeIcon icon={faChevronDown} />
          </DateSelector>
        </Container>
      }

      {children}
    </div>
  );
};

export default DatePickerLayout;
