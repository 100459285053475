import Chart from './Chart';
import formatDate from '../../../utils/formatDate';
import { secondary } from '../../../theme/colors';
import textOrPlaceholder from '../../../utils/textOrPlaceholder';
import { TariffDirection } from '../../../api/Optimise';
import H3 from './Styled/H3';
import Container from './Styled/Container';

const ExportSavingsChart = ({ chartData, date }) => {
  const exportKey = 'actual_export_earning';

  const exportEarnings =
    chartData?.reduce((acc, row) => acc + row[exportKey], 0) / 100;

  const dataAvailable = chartData?.some(row => row[exportKey] > 0);

  return (
    <Container>
      <H3 typeColour={secondary}>
        Grid <span className="type">export</span> earnings on {formatDate(date)}
        :{' '}
        <span className="cost">
          £{textOrPlaceholder(exportEarnings, exportEarnings.toFixed(2))}
        </span>
      </H3>
      <Chart
        data={chartData}
        dataKey={exportKey}
        barColor={secondary}
        yAxisLabel="Export earnings"
        xAxisLabel="Time of day"
        dataAvailable={dataAvailable}
        direction={TariffDirection.EXPORT}
      />
    </Container>
  );
};

export default ExportSavingsChart;
