import Chart from './Chart';
import formatDate from '../../../utils/formatDate';
import { primary } from '../../../theme/colors';
import textOrPlaceholder from '../../../utils/textOrPlaceholder';
import { TariffDirection } from '../../../api/Optimise';
import H3 from './Styled/H3';
import Container from './Styled/Container';

const ImportSavingsChart = ({ chartData, date }) => {
  const importKey = 'actual_import_cost';

  // Change import cost to a positive number so the bars go upwards
  const data = chartData?.map(row => ({
    ...row,
    [importKey]: parseFloat(row[importKey]) * -1,
    time: row.time,
  }));

  const importCosts = data?.reduce((acc, row) => acc + row[importKey], 0) / 100;

  const dataAvailable = data?.some(row => row[importKey] > 0);

  return (
    <Container>
      <H3 typeColour={primary}>
        Grid <span className="type">import</span> costs on {formatDate(date)}:{' '}
        <span className="cost">
          £{textOrPlaceholder(importCosts, importCosts.toFixed(2))}
        </span>
      </H3>
      <Chart
        data={data}
        dataKey={importKey}
        barColor={primary}
        yAxisLabel="Import costs"
        xAxisLabel="Time of day"
        dataAvailable={dataAvailable}
        direction={TariffDirection.IMPORT}
      />
    </Container>
  );
};

export default ImportSavingsChart;
