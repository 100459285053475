import * as Yup from 'yup';
import BaseConnectionContainer from './BaseConnectionContainer';

const fields = [
  {
    name: 'api_username',
    label: 'Sunsynk username',
    validation: Yup.string()
      .required('Please enter your Sunsynk username')
      .max(200, 'Username must be 200 characters or less'),
  },
  {
    name: 'api_password',
    label: 'Sunsynk password',
    type: 'password',
    validation: Yup.string()
      .required('Please enter your Sunsynk password')
      .max(200, 'Password must be 200 characters or less'),
  },
];

const SunsynkConnectionContainer = () => (
  <BaseConnectionContainer
    fields={fields}
    inverterManufacturer="Sunsynk"
    exampleModalContent={
      <div>
        <h3 className="mb-3">Sunsynk</h3>
        <p>
          This should be the same username and password that you use to log into
          the Sunsynk website.
        </p>
      </div>
    }
  />
);

export default SunsynkConnectionContainer;
