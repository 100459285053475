import styled from 'styled-components';

const Container = styled.div`
  margin-top: 50px;

  @media (min-width: 576px) {
    margin-top: 80px;
  }
`;

export default Container;
