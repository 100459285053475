import { createContext, useState } from 'react';
import { usePersistedState } from '../hooks';

const ConsentContext = createContext();

const ConsentFlows = {
  ONBOARDING: 'onboarding',
  RECONFIRM: 'reconfirm',
};

const ConsentProvider = ({ children }) => {
  const [lookedUpMpanKnown, setLookedUpMpanKnown] = usePersistedState(
    'lookedUpMpanKnown',
    undefined
  );
  const [lookedUpMpanObfuscated, setLookedUpMpanObfuscated] = usePersistedState(
    'lookedUpMpanObfuscated',
    undefined
  );
  const [lookedUpMpanHash, setLookedUpMpanHash] = usePersistedState(
    'lookedUpMpanHash',
    undefined
  );
  const [isManualAddress, setIsManualAddress] = usePersistedState(
    'isManualAddress',
    false
  );
  const [moveDate, setMoveDate] = usePersistedState('moveDate', undefined);
  const [mpan, setMpan] = usePersistedState('mpan', '');
  const [termsAndConditions, setTermsAndConditions] = usePersistedState(
    'termsAndConditions',
    undefined
  );
  const [redirectUrl, setRedirectUrl] = usePersistedState('redirectUrl', '');
  const [method, setMethod] = usePersistedState('method', undefined);
  const [paymentMethodId, setPaymentMethodId] = usePersistedState(
    'paymentMethodId',
    undefined
  );
  const [hasRegisterMpanTrustedConsent, setHasRegisterMpanTrustedConsent] =
    usePersistedState('hasRegisterMpanTrustedConsent', false);
  const [hasOnboarded, setHasOnboarded] = usePersistedState(
    'hasOnboarded',
    false
  );
  const initialUserState = {
    attributes: {
      given_name: '',
      family_name: '',
      email: '',
    },
  };
  const [currentUser, setUser] = usePersistedState(
    'currentUser',
    initialUserState
  );
  const [consentFlow, setConsentFlow] = usePersistedState(
    'consentFlow',
    ConsentFlows.ONBOARDING
  );
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteError, setDeleteError] = usePersistedState(
    'deleteError',
    undefined
  );

  const [showAlert, setShowAlert] = useState(false);
  const [alert, setAlert] = useState(undefined);

  const [reconfirmLastSeen, setReconfirmLastSeen] = usePersistedState(
    'lastDismissed',
    undefined
  );

  const [isSaving, setIsSaving] = usePersistedState(false);

  const [verificationMethods, setVerificationMethods] =
    usePersistedState(false);

  return (
    <ConsentContext.Provider
      value={{
        isManualAddress,
        setIsManualAddress,
        moveDate,
        setMoveDate,
        redirectUrl,
        setRedirectUrl,
        mpan,
        setMpan,
        termsAndConditions,
        setTermsAndConditions,
        lookedUpMpanKnown,
        setLookedUpMpanKnown,
        lookedUpMpanHash,
        setLookedUpMpanHash,
        lookedUpMpanObfuscated,
        setLookedUpMpanObfuscated,
        method,
        setMethod,
        paymentMethodId,
        setPaymentMethodId,
        hasRegisterMpanTrustedConsent,
        setHasRegisterMpanTrustedConsent,
        hasOnboarded,
        setHasOnboarded,
        currentUser,
        setUser,
        consentFlow,
        setConsentFlow,
        showDeleteAccountModal,
        setShowDeleteAccountModal,
        isDeleting,
        setIsDeleting,
        deleteError,
        setDeleteError,
        showAlert,
        setShowAlert,
        alert,
        setAlert,
        reconfirmLastSeen,
        setReconfirmLastSeen,
        isSaving,
        setIsSaving,
        verificationMethods,
        setVerificationMethods,
      }}>
      {children}
    </ConsentContext.Provider>
  );
};

export { ConsentContext, ConsentProvider, ConsentFlows };
