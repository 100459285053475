import { Route, Switch } from 'react-router-dom';
import { AppLayout, LoginCard } from '../ui';
import EditTariffContainer from './Edit/EditTariffContainer';

const EditContainer = () => (
  <AppLayout backgroundTint="blue">
    <LoginCard>
      <Switch>
        <Route path="/edit/tariff" component={EditTariffContainer} exact />
      </Switch>
    </LoginCard>
  </AppLayout>
);

export default EditContainer;
