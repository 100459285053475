import styled from 'styled-components';
import elecIcon from '../../../assets/svg/today/elec.svg';
import gridIcon from '../../../assets/svg/today/grid.svg';
import solarIcon from '../../../assets/svg/today/solar.svg';
import StyledTitle from '../../Styled/StyledTitle';
import textOrPlaceholder from '../../../utils/textOrPlaceholder';
import { StyledCardsContainer } from '../../Styled';

const Container = styled.div`
  margin-top: 24px;
`;

const InfoCard = styled.div`
  border: 1px solid #afb6b9;
  border-radius: 8px;
  padding: 10px 16px;
  margin-bottom: 16px;

  @media (min-width: 576px) {
    display: flex;
    align-items: center;
  }
`;

const Icon = styled.div`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    width: 60px;
    height: 60px;
    margin-bottom: 0;
    margin-right: 16px;
  }
`;

const SolarIcon = styled(Icon)`
  background: linear-gradient(225deg, #f8af1f, #f7d22d);
`;

const SolarIconImg = styled.img`
  width: 28px;

  @media (min-width: 576px) {
    width: 38px;
  }
`;

const ConsumptionIcon = styled(Icon)`
  background: linear-gradient(225deg, #ff594f, #e5098b);
`;

const ConsumptionIconImg = styled.img`
  width: 12px;

  @media (min-width: 576px) {
    width: 17px;
  }
`;

const GridIcon = styled(Icon)`
  border: 2px solid #0c1f28;
`;

const GridIconImg = styled.img`
  width: 22px;

  @media (min-width: 576px) {
    width: 31px;
  }
`;

const InfoContent = styled.div`
  flex: 1;
`;

const Label = styled.p`
  font-size: 14px;
  margin-bottom: 0;
  text-align: center;

  @media (min-width: 576px) {
    text-align: left;
    font-size: 16px;
  }
`;

const Value = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  text-align: center;

  @media (min-width: 576px) {
    text-align: left;
    font-size: 16px;
  }
`;

const GridInfo = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: 576px) {
    justify-content: flex-start;
  }
`;

const GridColumn = styled.div`
  padding: 0 30px;
`;

const FirstGridColumn = styled(GridColumn)`
  border-right: 1px solid #afb6b9;

  @media (min-width: 576px) {
    padding-left: 0;
  }
`;

const TodaySoFar = ({ todaySoFarData }) => (
  <Container>
    <StyledTitle>Today so far:</StyledTitle>
    <StyledCardsContainer>
      <InfoCard>
        <SolarIcon>
          <SolarIconImg src={solarIcon} alt="Solar" />
        </SolarIcon>
        <InfoContent>
          <Label>Solar generated:</Label>
          <Value>
            {textOrPlaceholder(
              todaySoFarData?.solar_generation,
              (todaySoFarData?.solar_generation / 1000)?.toFixed(2)
            )}{' '}
            kWh
          </Value>
        </InfoContent>
      </InfoCard>
      <InfoCard>
        <ConsumptionIcon>
          <ConsumptionIconImg src={elecIcon} alt="Consumption" />
        </ConsumptionIcon>
        <InfoContent>
          <Label>Household consumption:</Label>
          <Value>
            {textOrPlaceholder(
              todaySoFarData?.load_consumption / 1000,
              (todaySoFarData?.load_consumption / 1000)?.toFixed(2)
            )}{' '}
            kWh
          </Value>
        </InfoContent>
      </InfoCard>
      <InfoCard>
        <GridIcon>
          <GridIconImg src={gridIcon} alt="Grid" />
        </GridIcon>
        <InfoContent>
          <GridInfo>
            <FirstGridColumn>
              <Label>Exported:</Label>
              <Value>
                {textOrPlaceholder(
                  todaySoFarData?.exported,
                  (todaySoFarData?.exported / 1000)?.toFixed(2)
                )}{' '}
                kWh
              </Value>
            </FirstGridColumn>
            <GridColumn>
              <Label>Imported:</Label>
              <Value>
                {textOrPlaceholder(
                  todaySoFarData?.imported,
                  (todaySoFarData?.imported / 1000)?.toFixed(2)
                )}{' '}
                kWh
              </Value>
            </GridColumn>
          </GridInfo>
        </InfoContent>
      </InfoCard>
    </StyledCardsContainer>
  </Container>
);

export default TodaySoFar;
