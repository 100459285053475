import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const StyledButton = styled(Button)`
  text-decoration: none;
  font-size: 14px;
  padding: 9px 45px !important;
  border-width: 2px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const SignOutSettings = () => {
  const history = useHistory();
  return (
    <div>
      <p className="mt-3 mb-1">
        <strong>Sign out</strong>
      </p>
      <StyledButton
        onClick={() => history.push('/sign-out')}
        className="p-0"
        variant="secondary">
        Sign out of Loop Optimise
      </StyledButton>
    </div>
  );
};

export default SignOutSettings;
