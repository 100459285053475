import { useContext } from 'react';
import {
  AccordionContext,
  Accordion as BootstrapAccordion,
  Button,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import cx from 'classnames';
import { black } from '../theme/colors';

const StyledButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: ${black};

  .fa-chevron-down {
    transition: transform 0.2s linear;
    -webkit-transition: transform 0.2s linear;
    -moz-transition: transform 0.2s linear;
    -o-transition: transform 0.2s linear;
  }

  &.expand .fa-chevron-down {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
  }
`;

const CustomToggle = ({ eventKey, children }) => {
  const currentEventKey = useContext(AccordionContext); // <-- Will update every time the eventKey changes.
  const toggleOnClick = useAccordionToggle(eventKey);
  const isCurrentEventKey = currentEventKey === eventKey;

  return (
    <StyledButton
      variant="link"
      className={cx({
        expand: isCurrentEventKey,
      })}
      block
      onClick={toggleOnClick}>
      {children}
    </StyledButton>
  );
};

const StyledAccordion = styled(BootstrapAccordion)`
  > div {
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }
  }
`;

const StyledAccordionItem = styled.div`
  border: 1px solid #afb6b9;
  border-radius: 8px;
  padding: 8px 12px;
`;

const StyledAccordionToggle = styled(CustomToggle)`
  border: none;
  padding: 0;
  width: 100%;
  text-align: left;
  background-color: white;
  cursor: pointer;
  font-size: 14px;

  @media (min-width: 576px) {
    font-size: 16px;
  }
`;

const StyledAccordionCollapse = styled(BootstrapAccordion.Collapse)`
  margin-top: 16px;
`;

const Accordion = ({ data, defaultActiveKey = '' }) => (
  <StyledAccordion defaultActiveKey={defaultActiveKey}>
    {data?.map((item, index) => (
      <StyledAccordionItem key={index}>
        <StyledAccordionToggle
          variant="link"
          eventKey={index.toString()}
          callback={() => {}}>
          {item?.title}{' '}
          <FontAwesomeIcon className="accordion-arrow" icon={faChevronDown} />
        </StyledAccordionToggle>
        <StyledAccordionCollapse eventKey={index.toString()}>
          {item?.content}
        </StyledAccordionCollapse>
      </StyledAccordionItem>
    ))}
  </StyledAccordion>
);

export default Accordion;
