import { AppProvider } from './AppContext';
import { OnboardingProvider } from './OnboardingContext';
import { ConsentProvider } from './ConsentContext';

const ContextProvider = ({ children }) => {
  return (
    <AppProvider>
      <OnboardingProvider>
        <ConsentProvider>{children}</ConsentProvider>
      </OnboardingProvider>
    </AppProvider>
  );
};

export default ContextProvider;
