import { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { OnboardingContext } from '../../context/OnboardingContext';
import { createUserTrace } from '../../api/Optimise';
import { TariffForm } from '../../ui/Form';

const TariffContainer = () => {
  const history = useHistory();
  const { setImportTariffId, setExportTariffId, setProgressPercentage } =
    useContext(OnboardingContext);

  useEffect(() => {
    setProgressPercentage(20);
  }, []);

  const onSelectTariff = async ({ importTariffId, exportTariffId }) => {
    setImportTariffId(importTariffId);
    setExportTariffId(exportTariffId);
    await createUserTrace('Tariff', {
      importTariff: importTariffId,
      exportTariff: exportTariffId,
    });
    history.push('/onboarding/pv-system');
  };

  return (
    <>
      <h1 className="mb-3">Your tariff details</h1>
      <p>Please enter your import and export tariff details.</p>
      <p className="mb-0 mb-4">
        <a href="#">
          Why does Loop Optimise need your import and export tariff?
        </a>
      </p>
      <TariffForm onSubmit={onSelectTariff} setInitialValues={true} />
    </>
  );
};

export default TariffContainer;
