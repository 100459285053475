import * as Yup from 'yup';
import styled from 'styled-components';
import BaseConnectionContainer from './BaseConnectionContainer';
import SolisExampleOne from '../../../assets/images/solis-example-1.png';
import SolisExampleTwo from '../../../assets/images/solis-example-2.png';
import { primary } from '../../../theme/colors';

const StyledImage = styled.img`
  width: 100%;
`;

const StyledUnorderedList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    position: relative;
    padding-left: 1.5em;
    margin: 0.5em 0;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 0.5em;
      height: 0.5em;
      background-color: ${primary};
      border-radius: 50%;
    }
  }
`;

const fields = [
  {
    name: 'api_username',
    label: 'Solis API Key ID',
    validation: Yup.string()
      .required('Please enter your Solis API Key ID')
      .max(200, 'API Key ID must be 200 characters or less'),
  },
  {
    name: 'api_password',
    label: 'Solis API Key Secret',
    type: 'password',
    validation: Yup.string()
      .required('Please enter your Solis API Key Secret')
      .max(200, 'API Key Secret must be 200 characters or less'),
  },
];

const SolisConnectionContainer = () => (
  <BaseConnectionContainer
    fields={fields}
    inverterManufacturer="Solis"
    exampleModalContent={
      <div>
        <h3 className="mb-3">Solis</h3>
        <p className="mb-2">Details Required:</p>
        <StyledUnorderedList>
          <li>Solis API Key ID</li>
          <li>Solis API Key Secret</li>
        </StyledUnorderedList>
        <p>
          In order to get access, you need to submit a ticket to Solis
          requesting API access{' '}
          <a
            target="_blank"
            href="https://solis-service.solisinverters.com/en/support/solutions/articles/44002212561-request-api-access-soliscloud">
            Submit a ticket: Service Centre (solisinverters.com)
          </a>
          . Be sure to select 'API Access Request' and fill in your Solis Cloud
          email address:
        </p>
        <p>
          <StyledImage src={SolisExampleOne} />
        </p>
        <p>
          Once the ticket is processed, activate in API management under Basic
          Settings in Solis Cloud:
        </p>
        <p>
          <StyledImage src={SolisExampleTwo} />
        </p>
      </div>
    }
  />
);

export default SolisConnectionContainer;
