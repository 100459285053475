import { Formik, Field } from 'formik';
import { Button, Form } from 'react-bootstrap';
import cx from 'classnames';
import * as Yup from 'yup';
import styled from 'styled-components';
import ErrorLabel from '../ErrorLabel';
import convertToTitleCase from '../../utils/convertToTitleCase';
import EnergyIcon from '../EnergyIcon';
import { light, white } from '../../theme/colors';
import { getSupportedTariffs, TariffDirection } from '../../api/Optimise';
import { useContext, useEffect, useState } from 'react';
import { OnboardingContext } from '../../context/OnboardingContext';
import {
  getSupplierNames,
  getTariffsForSupplier,
} from '../../utils/tariffUtils';
import { getCurrentUserInfo } from '../../utils/auth';
import { getRegion } from '../../api/Location';
import LoopSpinner from '../LoopSpinner';

const TariffWrapper = styled.div`
  border: 1px solid ${light};
  border-radius: 4px;

  h3 {
    border-bottom: 1px solid ${light};
    padding: 10px 20px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .form-group {
    padding: 10px 20px;
    margin: 0;

    &:last-child {
      margin-bottom: 10px;
    }
  }
`;

const StyledAnchor = styled.a`
  text-decoration: underline !important;
`;

const StyledButton = styled(Button)`
  display: block;
  width: 48%;
  height: 44px;
  margin-bottom: 0;
`;

const StyledCancelButton = styled(StyledButton)`
  background-color: ${light};
  color: ${white};
  border: none;
  text-decoration: none !important;
  margin: 30px 0 0;

  &:hover {
    margin: 30px 0 0;
  }
`;

const TariffForm = ({ onSubmit, setInitialValues, cancelPath = null }) => {
  const {
    exportSupplier,
    setExportSupplier,
    importSupplier,
    setImportSupplier,
    importTariffId,
    exportTariffId,
  } = useContext(OnboardingContext);
  const [importSuppliers, setImportSuppliers] = useState([]);
  const [importSupplierTariffs, setImportSupplierTariffs] = useState([]);
  const [exportSuppliers, setExportSuppliers] = useState([]);
  const [exportSupplierTariffs, setExportSupplierTariffs] = useState([]);
  const [importTariffs, setImportTariffs] = useState([]);
  const [exportTariffs, setExportTariffs] = useState([]);
  const [regionId, setRegionId] = useState(null);
  const [submitting, setSubmitting] = useState(false);

  const validationSchema = Yup.object().shape({
    importSupplier: Yup.string()
      .nullable()
      .trim()
      .required('Please select your import energy supplier'),
    importTariffId: Yup.string()
      .nullable()
      .trim()
      .required('Please select your import tariff'),
    exportSupplier: Yup.string()
      .nullable()
      .trim()
      .required('Please select your export energy supplier'),
    exportTariffId: Yup.string()
      .nullable()
      .trim()
      .required('Please select your export tariff'),
  });

  const selectImportSupplier = supplier => {
    setImportSupplier(supplier);
    setImportSupplierTariffs(getTariffsForSupplier(supplier, importTariffs));
  };

  const selectExportSupplier = supplier => {
    setExportSupplier(supplier);
    setExportSupplierTariffs(getTariffsForSupplier(supplier, exportTariffs));
  };

  useEffect(() => {
    getCurrentUserInfo().then(user => {
      const postcode = user.attributes['custom:postcode'];
      if (!!postcode) {
        getRegion(postcode)
          .then(region => setRegionId(region.distributionId))
          .catch(e => console.error(e));
      }
    });
  }, []);

  useEffect(() => {
    if (!!regionId) {
      getSupportedTariffs(TariffDirection.IMPORT, regionId).then(res =>
        setImportTariffs(res?.supported_tariffs)
      );
      getSupportedTariffs(TariffDirection.EXPORT, regionId).then(res =>
        setExportTariffs(res?.supported_tariffs)
      );
    }
  }, [regionId]);

  useEffect(() => {
    const importSupplierNames = getSupplierNames(importTariffs);
    setImportSuppliers(importSupplierNames);
  }, [importTariffs]);

  useEffect(() => {
    const exportSupplierNames = getSupplierNames(exportTariffs);
    setExportSuppliers(exportSupplierNames);
  }, [exportTariffs]);

  useEffect(() => {
    if (!!importSupplier) {
      selectImportSupplier(importSupplier);
    }
    if (!!exportSupplier) {
      selectExportSupplier(exportSupplier);
    }
  }, [importTariffs, exportTariffs]);

  const initialValues = setInitialValues
    ? {
        importSupplier,
        importTariffId,
        exportSupplier,
        exportTariffId,
      }
    : {
        importSupplier: null,
        importTariffId: null,
        exportSupplier: null,
        exportTariffId: null,
      };

  const onSubmitWrapper = async values => {
    setSubmitting(true);
    await onSubmit(values);
    setSubmitting(false);
  };

  return importSuppliers?.length && exportSuppliers?.length ? (
    <div className="text-left">
      <Formik
        validationSchema={validationSchema}
        onSubmit={onSubmitWrapper}
        initialValues={initialValues}
        validateOnChange>
        {({ handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <TariffWrapper className="mb-4">
              <h3>
                <span>Import details</span>
                <EnergyIcon direction={TariffDirection.IMPORT} />
              </h3>
              <Field name="importSupplier">
                {({ field, meta }) => (
                  <Form.Group controlId="importSupplier" className="pb-4">
                    <Form.Label>Who’s your import energy provider?</Form.Label>
                    <Form.Control
                      as="select"
                      size="md"
                      value={field.value}
                      onChange={e => {
                        selectImportSupplier(e.target.value);
                        field.onChange(e);
                      }}
                      onBlur={field.onBlur}
                      isValid={!meta.error && meta.touched}
                      placeholder="Who’s your import energy provider?"
                      className={cx({ error: !!meta.error })}>
                      <optgroup>
                        <option value="" key="supplier-select">
                          Who’s your import energy provider?
                        </option>
                        {importSuppliers?.map((supplier, index) => (
                          <option
                            key={`import-supplier-${index}`}
                            value={supplier}>
                            {convertToTitleCase(supplier)}
                          </option>
                        ))}
                      </optgroup>
                    </Form.Control>
                    {meta.error && meta.touched && (
                      <ErrorLabel label={meta.error} />
                    )}
                  </Form.Group>
                )}
              </Field>
              <Field name="importTariffId">
                {({ field, meta }) => (
                  <Form.Group controlId="importTariffId">
                    <Form.Label>What’s your import tariff?</Form.Label>
                    <Form.Control
                      as="select"
                      size="md"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      isValid={!meta.error && meta.touched}
                      placeholder="What’s import your tariff?"
                      disabled={!importSupplierTariffs.length}
                      className={cx({ error: !!meta.error })}>
                      <optgroup>
                        <option value="" key="import-tariff-select">
                          What’s your import tariff?
                        </option>
                        {importSupplierTariffs?.map(
                          ({ tariff_id, tariff_name }, index) => (
                            <option
                              key={`import-tariff-${index}`}
                              value={tariff_id}>
                              {tariff_name}
                            </option>
                          )
                        )}
                      </optgroup>
                    </Form.Control>
                    {meta.error && meta.touched && (
                      <ErrorLabel label={meta.error} />
                    )}
                  </Form.Group>
                )}
              </Field>
              <Form.Group className="not-found-text">
                <div>
                  Can’t find your import tariff?
                  <StyledAnchor
                    href="https://share.hsforms.com/1qFeVIeHORx-7a6BChR_1qA2uro2"
                    target="_blank"
                    rel="noreferrer"
                    className="p-0 not-found-text ml-1">
                    Let us know here
                  </StyledAnchor>
                </div>
              </Form.Group>
            </TariffWrapper>
            <TariffWrapper>
              <h3>
                Export details
                <EnergyIcon direction={TariffDirection.EXPORT} />
              </h3>
              <Field name="exportSupplier">
                {({ field, meta }) => (
                  <Form.Group controlId="exportSupplier" className="pb-4">
                    <Form.Label>Who’s your export energy provider?</Form.Label>
                    <Form.Control
                      as="select"
                      size="md"
                      value={field.value}
                      onChange={e => {
                        selectExportSupplier(e.target.value);
                        field.onChange(e);
                      }}
                      onBlur={field.onBlur}
                      isValid={!meta.error && meta.touched}
                      placeholder="Who’s your export energy provider?"
                      className={cx({ error: !!meta.error })}>
                      <optgroup>
                        <option value="" key="export-supplier-select">
                          Who’s your export energy provider?
                        </option>
                        {exportSuppliers?.map((supplier, index) => (
                          <option
                            key={`export-supplier-${index}`}
                            value={supplier}>
                            {convertToTitleCase(supplier)}
                          </option>
                        ))}
                      </optgroup>
                    </Form.Control>
                    {meta.error && meta.touched && (
                      <ErrorLabel label={meta.error} />
                    )}
                  </Form.Group>
                )}
              </Field>
              <Field name="exportTariffId">
                {({ field, meta }) => (
                  <Form.Group controlId="exportTariffId">
                    <Form.Label>What’s your export tariff?</Form.Label>
                    <Form.Control
                      as="select"
                      size="md"
                      value={field.value}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      isValid={!meta.error && meta.touched}
                      placeholder="What’s your export tariff?"
                      disabled={!exportSupplierTariffs.length}
                      className={cx({ error: !!meta.error })}>
                      <optgroup>
                        <option value="" key="export-tariff-select">
                          What’s your export tariff?
                        </option>
                        {exportSupplierTariffs?.map(
                          ({ tariff_id, tariff_name }, index) => (
                            <option
                              key={`export-tariff-${index}`}
                              value={tariff_id}>
                              {tariff_name}
                            </option>
                          )
                        )}
                      </optgroup>
                    </Form.Control>
                    {meta.error && meta.touched && (
                      <ErrorLabel label={meta.error} />
                    )}
                  </Form.Group>
                )}
              </Field>
              <Form.Group className="not-found-text">
                <div>
                  Can’t find your export tariff?
                  <StyledAnchor
                    href="https://share.hsforms.com/1qFeVIeHORx-7a6BChR_1qA2uro2"
                    target="_blank"
                    rel="noreferrer"
                    className="p-0 not-found-text ml-1">
                    Let us know here
                  </StyledAnchor>
                </div>
              </Form.Group>
            </TariffWrapper>
            <Form.Group>
              {!submitting ? (
                <>
                  {cancelPath ? (
                    <div className="d-flex justify-content-between">
                      <StyledCancelButton variant="light" href={cancelPath}>
                        Cancel
                      </StyledCancelButton>
                      <StyledButton
                        variant="primary"
                        disabled={
                          !isValid ||
                          submitting ||
                          !importSupplierTariffs.length ||
                          !exportSupplierTariffs.length
                        }
                        type="submit">
                        Confirm
                      </StyledButton>
                    </div>
                  ) : (
                    <Button
                      variant="primary"
                      disabled={
                        !isValid ||
                        submitting ||
                        !importSupplierTariffs.length ||
                        !exportSupplierTariffs.length
                      }
                      block
                      className="mb-0"
                      type="submit">
                      Continue
                    </Button>
                  )}
                </>
              ) : (
                <div className="mt-4">
                  <LoopSpinner />
                </div>
              )}
            </Form.Group>
          </Form>
        )}
      </Formik>
    </div>
  ) : (
    <div className="mt-4">
      <LoopSpinner />
    </div>
  );
};

export default TariffForm;
