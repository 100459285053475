import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import ErrorDisc from '../../../assets/svg/disc/error-disc.svg';
import { SpacerHr } from '../../Styled';
import { grey, light } from '../../../theme/colors';

const StyledDarkButton = styled(Button)`
  background-color: ${light};
  border-color: ${light};

  &:hover {
    background-color: ${grey};
    border-color: ${grey};
  }
`;

const ErrorModal = ({ show, onNext, onCancel, onSupport }) => (
  <Modal aria-labelledby="contained-modal-title-vcenter" centered show={show}>
    <Modal.Body className="p-4">
      <Container fluid>
        <Row>
          <Col className="text-center pb-2">
            <h1 className="pb-2">Error updating tariff information</h1>
            <p className="p-2">
              <img src={ErrorDisc} />
            </p>
            <p>There's been an error updating your tariff information.</p>
            <Button onClick={onNext} block>
              Try again
            </Button>
            <StyledDarkButton
              className="mb-2"
              variant="dark"
              onClick={onCancel}
              block>
              Cancel
            </StyledDarkButton>
            <SpacerHr label="or" />
            <Button variant="secondary" onClick={onSupport} block>
              Contact Loop Optimise Support
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal.Body>
  </Modal>
);

export default ErrorModal;
