import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import CheckDisc from '../../../assets/svg/disc/check-disc.svg';

const SuccessModal = ({ show, onNext }) => (
  <Modal aria-labelledby="contained-modal-title-vcenter" centered show={show}>
    <Modal.Body className="p-4">
      <Container fluid>
        <Row>
          <Col className="text-center pb-2">
            <h1 className="pb-2">Tariff information updated</h1>
            <p className="p-2">
              <img src={CheckDisc} />
            </p>
            <p>Your tariff information has been successfully updated.</p>
            <Button onClick={onNext} block>
              Continue
            </Button>
          </Col>
        </Row>
      </Container>
    </Modal.Body>
  </Modal>
);

export default SuccessModal;
