import moment from 'moment-timezone';
import { TariffDirection } from '../api/Optimise';

/**
 * Constants to define description keys and energy usage descriptions
 */
export const DESCRIPTION_KEY = 'description';
export const EXPORT_SOLAR =
  'Export excess solar generation rather than storing it.';
export const AVERAGE_COST = 'Average cost electricity.';
export const HOLD_BATTERY =
  'Hold the battery state of charge to be used at peak times.';
export const CHARGE_BATTERY = 'Charge the battery when electricity is cheap.';
export const USE_BATTERY =
  'Use the battery and export when electricity is expensive.';

/**
 * Names of rate categories for comparison
 */
export const RateCategories = {
  CHEAP: 'Cheap rate',
  DAY: 'Day rate',
  PEAK: 'Peak rate',
};

/**
 * Calculate total savings by comparing battery-based savings and dumb battery savings.
 *
 * @param {Array} savings - Array of savings data objects.
 * @returns {number} - The difference between smart and dumb battery savings.
 */
export const getSavingsTotal = savings => {
  let savingsTotal = 0;
  let dumbBatteryTotal = 0;

  // Loop through savings data to calculate totals
  savings.forEach(item => {
    savingsTotal += item.battery_earning - item.battery_cost;
    dumbBatteryTotal += item.dumb_battery_earning - item.dumb_battery_cost;
  });

  // Return the difference between smart and dumb battery savings
  return savingsTotal - dumbBatteryTotal;
};

/**
 * Calculate a specific quantile from an array of numbers.
 *
 * @param {Array} arr - Array of numbers.
 * @param {number} q - Quantile to calculate (0 <= q <= 1).
 * @returns {number} - The calculated quantile value.
 */
export const getQuantile = (arr, q) => {
  const sorted = arr.slice().sort((a, b) => a - b);
  const pos = (sorted.length - 1) * q;
  const base = Math.floor(pos);
  const rest = pos - base;

  // Return interpolated value or the exact base value if at the end
  if (sorted[base + 1] !== undefined) {
    return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
  } else {
    return sorted[base];
  }
};

/**
 * Determine rate categories (Cheap, Day, Peak) based on unique rates in the analytics data.
 *
 * This function classifies import or export rates into predefined categories to facilitate
 * energy usage recommendations and tariff analysis. The classification depends on the
 * number of unique rates present in the dataset:
 *
 * - **1 Unique Rate**: All entries are classified as "Day rate".
 * - **2 Unique Rates**:
 *   - The lowest rate is classified as "Cheap rate".
 *   - The highest rate is classified as "Day rate".
 *   - Entries are categorized accordingly.
 * - **3 Unique Rates**:
 *   - The lowest rate is "Cheap rate".
 *   - The medium rate is "Day rate".
 *   - The highest rate is "Peak rate".
 * - **>3 Unique Rates**:
 *   - Rates are grouped dynamically using quantiles:
 *     - Rates below the 20th percentile are "Cheap rate".
 *     - Rates above the 80th percentile are "Peak rate".
 *     - Remaining rates are classified as "Day rate".
 *   - This ensures flexibility and adaptability for dynamic tariff structures.
 *
 * @param {Array} analyticsData - The array of analytics data containing rate information.
 * @param {string} direction - Indicates whether to classify based on "import_rate" or "export_rate".
 * @returns {Object} - An object containing categorized rates:
 *   - `cheap`: Array of entries classified as "Cheap rate".
 *   - `day`: Array of entries classified as "Day rate".
 *   - `peak`: Array of entries classified as "Peak rate".
 */
export const addRateCategoriesToRecords = (analyticsData, direction) => {
  const rates = analyticsData.map(record =>
    parseFloat(record[`${direction}_rate`])
  );
  const uniqueRates = [...new Set(rates)].sort((a, b) => a - b);

  if (uniqueRates.length === 1) {
    // Single rate: All records are "Day rate"
    analyticsData.forEach(record => {
      record[`${direction}_rate_category`] = RateCategories.DAY;
      record[DESCRIPTION_KEY] = HOLD_BATTERY;
    });
  } else if (uniqueRates.length === 2) {
    // Two unique rates: classify as "Cheap" and "Day"
    const [cheapRate, peakRate] = uniqueRates;
    analyticsData.forEach(record => {
      if (parseFloat(record[`${direction}_rate`]) === cheapRate) {
        record[`${direction}_rate_category`] = RateCategories.CHEAP;
        record[DESCRIPTION_KEY] = CHARGE_BATTERY;
      } else if (parseFloat(record[`${direction}_rate`]) === peakRate) {
        record[`${direction}_rate_category`] = RateCategories.DAY;
        record[DESCRIPTION_KEY] = USE_BATTERY;
      }
    });
  } else if (uniqueRates.length === 3) {
    // Three unique rates: classify as "Cheap", "Day", and "Peak"
    const [cheapRate, dayRate, peakRate] = uniqueRates;
    analyticsData.forEach(record => {
      if (parseFloat(record[`${direction}_rate`]) === cheapRate) {
        record[`${direction}_rate_category`] = RateCategories.CHEAP;
        record[DESCRIPTION_KEY] = CHARGE_BATTERY;
      } else if (parseFloat(record[`${direction}_rate`]) === dayRate) {
        record[`${direction}_rate_category`] = RateCategories.DAY;
        record[DESCRIPTION_KEY] = HOLD_BATTERY;
      } else if (parseFloat(record[`${direction}_rate`]) === peakRate) {
        record[`${direction}_rate_category`] = RateCategories.PEAK;
        record[DESCRIPTION_KEY] = USE_BATTERY;
      }
    });
  } else {
    // Flexible classification: group rates using quantiles
    const cheapRateThreshold = getQuantile(rates, 0.2);
    const peakRateThreshold = getQuantile(rates, 0.8);

    analyticsData.forEach(record => {
      const rate = parseFloat(record[`${direction}_rate`]);
      if (rate <= cheapRateThreshold) {
        record[`${direction}_rate_category`] = RateCategories.CHEAP;
        record[DESCRIPTION_KEY] = CHARGE_BATTERY;
      } else if (rate >= peakRateThreshold) {
        record[`${direction}_rate_category`] = RateCategories.PEAK;
        record[DESCRIPTION_KEY] = USE_BATTERY;
      } else {
        record[`${direction}_rate_category`] = RateCategories.DAY;
        record[DESCRIPTION_KEY] = HOLD_BATTERY;
      }
    });
  }
  return analyticsData;
};

/**
 * Prepare data for rendering in savings chart.
 *
 * @param {Array} analyticsData - Array of raw analytics data.
 * @returns {Array} - Processed data ready for chart rendering.
 */
export const transformAnalyticsDataForChart = analyticsData =>
  analyticsData.map(row => ({
    ...row,
    start_date: moment.utc(row.start_date).tz('Europe/London').toISOString(),
    solar_generation: parseFloat(row.solar_generation),
    load_consumption: parseFloat(row.load_consumption),
    import_rate: parseFloat(row.import_rate),
    export_rate: parseFloat(row.export_rate),
    imported: parseFloat(row.imported),
    exported: parseFloat(row.exported),
    alternative_import_cost: parseFloat(row.alternative_import_cost),
    actual_import_cost: -parseFloat(row.actual_import_cost),
    actual_export_earning: -parseFloat(row.actual_export_earning),
    time: moment
      .utc(row.start_date)
      .tz('Europe/London')
      .add(30, 'minutes')
      .format('HH:mm'),
  }));

export const extractRates = records => {
  const categories = Object.values(RateCategories); // Extract category names
  const result = {
    import: {},
    export: {},
  };

  // Initialize result object for each category and type (import/export)
  categories.forEach(category => {
    result.import[category] = {
      rates: [],
      minRate: null,
      maxRate: null,
    };
    result.export[category] = {
      rates: [],
      minRate: null,
      maxRate: null,
    };
  });

  // Process records
  records.forEach(record => {
    // Handle export rates
    if (categories.includes(record.export_rate_category)) {
      const exportRate = record.export_rate;
      result.export[record.export_rate_category].rates.push(
        exportRate.toFixed(2)
      );
    }

    // Handle import rates
    if (categories.includes(record.import_rate_category)) {
      const importRate = record.import_rate;
      result.import[record.import_rate_category].rates.push(
        importRate.toFixed(2)
      );
    }
  });

  // Calculate min and max rates for each category
  categories.forEach(category => {
    // Export rates
    const exportRates = result.export[category].rates;
    if (exportRates.length > 0) {
      result.export[category].minRate = Math.min(...exportRates);
      result.export[category].maxRate = Math.max(...exportRates);
    }

    // Import rates
    const importRates = result.import[category].rates;
    if (importRates.length > 0) {
      result.import[category].minRate = Math.min(...importRates);
      result.import[category].maxRate = Math.max(...importRates);
    }
  });

  return result;
};
