const formatMoneyValue = moneyValue => {
  if (moneyValue < 10) {
    return `${moneyValue.toFixed(2)}p`;
  } else if (moneyValue < 100) {
    return `${Math.round(moneyValue)}p`;
  } else {
    return `£${(moneyValue / 100).toFixed(2)}`;
  }
};

export default formatMoneyValue;
