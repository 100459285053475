import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { OnboardingContext } from '../../context/OnboardingContext';
import { getUserDetails, updateUserDetails } from '../../api/Optimise';
import InformationPanel from '../../ui/Dashboard/Settings/InformationPanel';
import { StyledHr } from '../../ui/Styled';
import { AppContext } from '../../context/AppContext';
import SuccessModal from '../../ui/Modal/EditTariff/SuccessModal';
import ErrorModal from '../../ui/Modal/EditTariff/ErrorModal';
import { TariffForm } from '../../ui/Form';

const EditTariffContainer = () => {
  const history = useHistory();
  const { setImportTariffId, setExportTariffId } =
    useContext(OnboardingContext);
  const { userDetails, setUserDetails, userSub } = useContext(AppContext);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    getUserDetails(userSub).then(res => {
      setUserDetails(res);
    });
  }, []);

  const onSelectTariff = async ({ importTariffId, exportTariffId }) => {
    setImportTariffId(importTariffId);
    setExportTariffId(exportTariffId);

    updateUserDetails({
      import_tariff_id: importTariffId,
      export_tariff_id: exportTariffId,
    })
      .then(() => setShowSuccessModal(true))
      .catch(error => {
        setShowErrorModal(true);
        console.error(error);
      });
  };

  return (
    <>
      <SuccessModal
        show={showSuccessModal}
        onNext={() => history.push('/app/settings')}
      />
      <ErrorModal
        show={showErrorModal}
        onNext={() => setShowErrorModal(false)}
        onCancel={() => history.push('/app/settings')}
        onSupport={() =>
          (window.location.href = 'https://kb.loop.homes/kb-tickets/new')
        }
      />
      <h1 className="mb-1">Your tariff details</h1>
      <InformationPanel title="Your current tariff details">
        <div className="text-left">
          <p className="mt-1">
            <strong>Import energy provider:</strong>{' '}
            {userDetails?.tariffs.import_tariff.supplier}
          </p>
          <p>
            <strong>Import tariff:</strong>{' '}
            {userDetails?.tariffs.import_tariff.tariff_name}
          </p>
          <StyledHr />
          <p>
            <strong>Export energy provider:</strong>{' '}
            {userDetails?.tariffs.export_tariff.supplier}
          </p>
          <p className="mb-1">
            <strong>Export tariff:</strong>{' '}
            {userDetails?.tariffs.export_tariff.tariff_name}
          </p>
        </div>
      </InformationPanel>
      <div className="text-left mb-0 pb-1 mt-4">
        <strong>Update your tariff details</strong>
      </div>
      <TariffForm
        onSubmit={onSelectTariff}
        setInitialValues={false}
        cancelPath="/app/settings"
      />
    </>
  );
};

export default EditTariffContainer;
