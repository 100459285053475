import { useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import styled from 'styled-components';
import terms from '../../data/terms.json';
import { createUserTrace } from '../../api/Optimise';

const StyledReactMarkdown = styled(ReactMarkdown)`
  text-align: left;
  max-height: 50vh;
  margin-bottom: 30px;
  overflow: auto;
  padding-right: 15px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  ul {
    list-style: none;
    padding-left: 18px;

    li {
      margin-bottom: 8px;

      &::before {
        content: '\\2022';
        color: #ff0085;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        font-size: 20px;
      }
    }
  }
`;

const TermsModal = ({ showTerms, onHideTerms, setFullTerms }) => {
  setFullTerms(terms);

  useEffect(() => {
    createUserTrace('Full Terms Modal', terms);
  }, []);

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={showTerms}
      onHide={onHideTerms}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="pt-0">
        <Container fluid>
          <Row>
            <Col className="text-center">
              <h1 className="pb-4 text-left">{terms.fullTermsTitle}</h1>
              <StyledReactMarkdown>{terms.fullTermsBody}</StyledReactMarkdown>
              <Button variant="secondary" block onClick={onHideTerms}>
                Close
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default TermsModal;
