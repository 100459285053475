import { useEffect, useContext, useState } from 'react';
import moment from 'moment';
import RecentBatteryStatus from '../../ui/Dashboard/Today/RecentBatteryStatus';
import TodaySoFar from '../../ui/Dashboard/Today/TodaySoFar';
import FurtherInfo from '../../ui/Dashboard/Today/FurtherInfo';
import withPageTitle from '../../hocs/withPageTitle';
import {
  getCurrentBehaviourForecast,
  getTwentyHoursOfSystemData,
  getUserDetails,
  getUserMpan,
  getUserGridCampaigns,
  getUserGridEvents,
  tdasCampaignId,
} from '../../api/Optimise';
import LoadingCard from '../../ui/LoadingCard';
import WarningPanel from '../../ui/WarningPanel';
import InfoModal from '../../ui/Modal/InfoModal';
import { AppContext } from '../../context/AppContext';
import aggregateRecords from '../../utils/aggregateRecords';
import TurnDownAndSave from '../../ui/Dashboard/Today/TurnDownAndSave';
import TdasModal, { MODAL_VIEWS } from '../../ui/Modal/TdasModal';
import { useHandleModalPath } from '../../hooks';
import getNextFutureEvent from '../../utils/getNextFutureEvent';

const TodayContainer = () => {
  const {
    setAnalyticsStartDate,
    hideTdasBanner,
    setHideTdasBanner,
    userSub,
    showTdasModal,
    setShowTdasModal,
    hasShownTdasModal,
    setHasShownTdasModal,
    setTdasModalView,
    userMpan,
    setUserMpan,
    tdasIsActive,
    userCampaigns,
    setUserCampaigns,
    setGridEvent,
    setUserEvents,
  } = useContext(AppContext);
  const [twentyFourHoursOfData, setTwentyFourHoursOfData] = useState(null);
  const [latestRecord, setlatestRecord] = useState(null);
  const [behaviourForecast, setBehaviourForecast] = useState(null);
  const [lastInstruction, setLastInstruction] = useState(null);
  const [nextInstruction, setNextInstruction] = useState(null);
  const [behaviorChartData, setBehaviourChartData] = useState(null);
  const [futureRecords, setFutureRecords] = useState(null);
  const [historicChartData, setHistoricChartData] = useState(null);
  const [todaySoFarData, setTodaySoFarData] = useState(null);
  const [showNoDataModal, setShowNoDataModal] = useState(false);
  const [loadingUserMpan, setLoadingUserMpan] = useState(false);
  const [fetchingTwentyFourHoursOfData, setFetchingTwentyFourHoursOfData] =
    useState(false);

  useEffect(() => {
    const asyncUseEffect = async () => {
      setFetchingTwentyFourHoursOfData(true);
      try {
        const data = await getTwentyHoursOfSystemData(userSub).catch(error => {
          console.error('Error fetching historic system data:', error);
        });
        if (!!data) {
          const latestRecord = data[data?.length - 1];
          setlatestRecord(latestRecord);
        }
        setTwentyFourHoursOfData(data);
        setFetchingTwentyFourHoursOfData(false);
      } catch (error) {
        setFetchingTwentyFourHoursOfData(false);
        console.error(error);
      }

      try {
        const user = await getUserDetails(userSub);
        if (user?.analytics_data_range?.start_date) {
          setAnalyticsStartDate(user?.analytics_data_range?.start_date);
        } else {
          setAnalyticsStartDate(null);
        }
      } catch (error) {
        console.error(error);
        setAnalyticsStartDate(null);
      }
    };
    asyncUseEffect();
  }, []);

  useEffect(() => {
    const asyncUseEffect = async () => {
      const data = await getCurrentBehaviourForecast(userSub).catch(error => {
        console.error('Error fetching current behaviour forecast:', error);
      });
      const timeThreshold = moment();

      const futureRecords = data?.filter(record => {
        const recordTime = moment
          .utc(record.target_datetime_utc * 1000)
          .local();
        return recordTime.isAfter(timeThreshold);
      });

      setBehaviourForecast(futureRecords);
      if (futureRecords?.length) {
        setLastInstruction(data[0]);
        setNextInstruction(data[1]);
      }
    };
    asyncUseEffect();
  }, []);

  useEffect(() => {
    if (!!twentyFourHoursOfData && !!behaviourForecast) {
      const now = moment();
      const twelveHoursAgo = moment().subtract(12, 'hours');
      const twelveHoursLater = moment().add(12, 'hours');

      // build data for chart
      const futureRecords = behaviourForecast?.filter(row => {
        const itemDate = moment.utc(row.target_datetime_utc * 1000);
        return itemDate.isBetween(now, twelveHoursLater);
      });

      setFutureRecords(futureRecords);

      const futureChartData = futureRecords?.map(row => ({
        data: row,
        x: moment
          .utc(row.target_datetime_utc * 1000)
          .local()
          .format('HH:mm'),
        y:
          row.optimal_target_soc_original <= 100
            ? row.optimal_target_soc_original
            : 100,
      }));

      const pastRecords = twentyFourHoursOfData?.filter(row => {
        const itemDate = moment.unix(row.end_date);
        return itemDate.isBetween(twelveHoursAgo, now);
      });

      const historicChartData = pastRecords?.map(row => ({
        data: row,
        x: moment.unix(row.end_date).format('HH:mm'),
        y: row.battery_soc <= 100 ? row.battery_soc : 100,
      }));

      const behaviorChartRawData = [...historicChartData, ...futureChartData];

      // de-duplicate the chart array
      const uniqueBehaviorChartData = behaviorChartRawData.filter(
        (obj, index, self) => index === self.findIndex(o => o?.x === obj?.x)
      );

      setTodaySoFarData(aggregateRecords(pastRecords));
      setHistoricChartData(historicChartData);
      setBehaviourChartData(uniqueBehaviorChartData);
    }
  }, [twentyFourHoursOfData, behaviourForecast]);

  useEffect(() => {
    const asyncFetchUserLoopAccount = async () => {
      setLoadingUserMpan(true);
      try {
        await getUserMpan().then(mpan => {
          if (mpan.length) {
            setUserMpan(mpan.pop());
          } else if (!hasShownTdasModal) {
            onOpenTdasModal();
          }
        });
      } catch (e) {
        if (e.status !== 404) {
          console.error('Error while fetching user loop account:', e);
        }
      } finally {
        setLoadingUserMpan(false);
      }
    };
    asyncFetchUserLoopAccount();
  }, [userCampaigns]);

  useEffect(() => {
    if (!!userMpan) {
      getUserGridCampaigns()
        .then(res => {
          setUserCampaigns(res);
          getUserGridEvents(tdasCampaignId).then(res => {
            if (!!res?.length) {
              setUserEvents(res);
              setGridEvent(getNextFutureEvent(res));
            }
          });
        })
        .catch(error => console.error(error));
    }
  }, [userMpan]);

  const onOpenTdasModal = (nextView = MODAL_VIEWS.INTRO) => {
    setShowTdasModal(true);
    setHasShownTdasModal(true);
    setTdasModalView(nextView);
  };

  const onCloseTdasModal = () => {
    setShowTdasModal(false);
  };

  const onDismissTdasModal = () => setHideTdasBanner(true);

  useHandleModalPath('/app/today/tdas', '/app/today', onOpenTdasModal);
  useHandleModalPath(
    '/app/today/success',
    '/app/today',
    onOpenTdasModal,
    MODAL_VIEWS.SUCCESS
  );
  useHandleModalPath(
    '/app/today/error',
    '/app/today',
    onOpenTdasModal,
    MODAL_VIEWS.ERROR
  );

  const intersectionX =
    historicChartData && historicChartData[historicChartData?.length - 1]?.x;
  const intersectionY =
    (behaviorChartData &&
      behaviorChartData.find(d => d?.x === intersectionX)?.y) ||
    0;

  const notEnoughData =
    fetchingTwentyFourHoursOfData ||
    !twentyFourHoursOfData ||
    !futureRecords?.length;

  return !fetchingTwentyFourHoursOfData ? (
    <div>
      {tdasIsActive && (
        <TdasModal show={showTdasModal} onHide={onCloseTdasModal} />
      )}
      {notEnoughData && (
        <>
          <WarningPanel
            title="Not enough data to show Today's behaviour."
            body="Please wait 30 minutes for the Loop Optimise calculations to be made."
            linkText="Why does Loop Optimise not have my data yet?"
            linkAction={() => setShowNoDataModal(true)}
          />

          <InfoModal
            show={showNoDataModal}
            onClose={() => setShowNoDataModal(false)}
            infoContent={
              <div>
                Loop Optimise processes a variety of data sources to determine
                actions that affect your battery's state of charge. These data
                sources update at different intervals—every 5, 15, and 30
                minutes. As a result, new actions are only taken once all
                updated data has been gathered, which is why Loop Optimise
                operates on the hour and half-hour.
                <br />
                <br />
                So, when you first sign up, you may need to wait up to 30
                minutes for any information to be available.
              </div>
            }
          />
        </>
      )}
      {tdasIsActive && !hideTdasBanner && !userMpan && !loadingUserMpan && (
        <TurnDownAndSave
          onShowTdas={onOpenTdasModal}
          onDismiss={onDismissTdasModal}
        />
      )}
      <RecentBatteryStatus
        latestRecord={latestRecord}
        lastInstruction={lastInstruction}
        nextInstruction={nextInstruction}
      />
      <TodaySoFar todaySoFarData={todaySoFarData} />
      <FurtherInfo
        notEnoughData={notEnoughData}
        intersectionX={intersectionX}
        intersectionY={intersectionY}
        historicChartData={historicChartData}
        behaviorChartData={behaviorChartData}
        futureRecords={futureRecords}
      />
    </div>
  ) : (
    <div className="pt-3">
      <LoadingCard />
    </div>
  );
};

export default withPageTitle('Your Optimisation', TodayContainer);
