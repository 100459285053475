import styled from 'styled-components';
import { StyledH3 } from '../../../Styled';

const H3 = styled(StyledH3)`
  margin-bottom: 5px;

  span.type {
    color: ${props => props.typeColour};
  }

  span.cost {
    font-weight: normal;
  }
`;

export default H3;
