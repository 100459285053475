const primary = '#ff0085';
const secondary = '#2648b2';
const tertiary = '#473eac';
const dark = '#0c1f28';
const light = '#afb6b9';
const extraLight = '#f0f0f0';
const white = '#ffffff';
const black = '#000000';
const grey = '#d3d3d3';
const off = '#858f93';
const offWhite = '#f5f5f5';
const primaryHover = '#d1006d';
const error = '#ff5a35';
const success = '#3Cba92';
const external = '#05A0D1';
const cheap = '#d8f1e9';
const day = '#e1d6ea';
const peak = '#ffeea0';
const primaryGradient = 'linear-gradient(260deg, #822a9f, #3c41ae 100%)';
const secondaryGradient = 'linear-gradient(260deg, #bc1793, #772da1 100%)';
const tertiaryGradient = `linear-gradient(to right, ${secondary}, ${tertiary})`;
const successGradient = 'linear-gradient(80deg, #0ba360, #3cba92 100%)';
const dangerGradient = 'linear-gradient(260deg, #ff6a44, #ff2b69 100%)';
const warningGradient = 'linear-gradient(260deg, #ff594f, #e5098b 100%);';
const infoGradient = 'linear-gradient(260deg, #6433a5, #2747b1 100%)';
const appGradient =
  'linear-gradient(80deg, rgba(255,255,255,1) 0%, ' +
  'rgba(255,255,255,1) 50%, ' +
  'rgba(245,245,245,1) 50%, ' +
  'rgba(245,245,245,1) 100%)';
const electricityGradient = 'linear-gradient(80deg, #e5098b, #ff594f 100%)';
const heatingGradient = 'linear-gradient(80deg, #0259ab, #19d5ff 100%)';
const carGradient = 'linear-gradient(80deg, #629c10, #74b816 100%)';
const solarGradient = 'linear-gradient(80deg, #f8af1f, #f7d22d 100%)';
const solarGradientReverse = 'linear-gradient(-80deg, #f8af1f, #f7d22d 100%)';
const primaryGradientVertical =
  'linear-gradient(180deg, #822a9f, #3c41ae 100%)';
const secondaryGradientVertical =
  'linear-gradient(180deg, #bc1793, #772da1 100%)';
const successGradientVertical = 'linear-gradient(0deg, #0ba360, #3cba92 100%)';
const dangerGradientVertical = 'linear-gradient(180deg, #ff6a44, #ff2b69 100%)';
const warningGradientVertical =
  'linear-gradient(180deg, #ff594f, #e5098b 100%);';
const infoGradientVertical = 'linear-gradient(180deg, #6433a5, #2747b1 100%)';
const appGradientVertical =
  'linear-gradient(180deg, rgba(255,255,255,1) 0%, ' +
  'rgba(255,255,255,1) 50%, ' +
  'rgba(245,245,245,1) 50%, ' +
  'rgba(245,245,245,1) 100%)';
const electricityGradientVertical =
  'linear-gradient(180deg, #e5098b, #ff594f 100%)';
const heatingGradientVertical =
  'linear-gradient(180deg, #0259ab, #19d5ff 100%)';
const carGradientVertical = 'linear-gradient(180deg, #629c10, #74b816 100%)';
const solarGradientVertical = 'linear-gradient(180deg, #f8af1f, #f7d22d 100%)';

export {
  primary,
  secondary,
  tertiary,
  dark,
  light,
  extraLight,
  white,
  black,
  grey,
  off,
  offWhite,
  primaryHover,
  error,
  success,
  external,
  cheap,
  day,
  peak,
  primaryGradient,
  secondaryGradient,
  tertiaryGradient,
  successGradient,
  dangerGradient,
  warningGradient,
  infoGradient,
  appGradient,
  electricityGradient,
  heatingGradient,
  carGradient,
  solarGradient,
  solarGradientReverse,
  primaryGradientVertical,
  secondaryGradientVertical,
  successGradientVertical,
  dangerGradientVertical,
  warningGradientVertical,
  infoGradientVertical,
  appGradientVertical,
  electricityGradientVertical,
  heatingGradientVertical,
  carGradientVertical,
  solarGradientVertical,
};
