import styled from 'styled-components';
import { StyledH3 } from '../../Styled';
import { extraLight } from '../../../theme/colors';
import RippleLoaderLines from '../../RippleLoaderLines';

const Container = styled.div`
  margin-top: 24px;
`;

const InfoContainer = styled.div`
  border: 1px solid #afb6b9;
  padding: 10px 14px;
  border-radius: 4px;

  &.secondary {
    background: ${extraLight};
    border-color: ${extraLight};
    border-radius: 4px;
  }
`;

const UnorderedList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin-bottom: 8px;
    font-size: 14px;

    @media (min-width: 576px) {
      font-size: 16px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledAnchor = styled.a`
  font-weight: normal;
  margin-right: 1px;
`;

const InformationPanel = ({
  title,
  children,
  isLoading = false,
  variant = 'primary',
  loadingLines = 4,
  editLabel = 'Edit',
  onEdit = null,
}) => (
  <Container>
    <StyledH3 className="d-flex justify-content-between">
      <span>{title}</span>{' '}
      {onEdit && editLabel && (
        <StyledAnchor onClick={onEdit}>{editLabel}</StyledAnchor>
      )}
    </StyledH3>
    <InfoContainer className={variant}>
      {isLoading ? (
        <RippleLoaderLines lines={loadingLines} />
      ) : (
        <UnorderedList>{children}</UnorderedList>
      )}
    </InfoContainer>
  </Container>
);

export default InformationPanel;
